<template>
  <div class="home-index frame-content-common-2022 d-flex align-items-center justify-content-center">
    <div class="content_index">
      <div>
        <i class="el-icon-search css_icon"></i>
        <div style="position: relative" class="index-search">
          <el-select style="width: 100%"
                     v-model="value"
                     filterable
                     remote
                     reserve-keyword
                     placeholder="Tìm bài viết"
                     :remote-method="remoteMethod"
                     @change="handleOptionChange"
                     :loading="loading">
            <el-option
              v-for="item in options"
              :key="item.key"
              :label="item.title"
              :value="item.key">
            </el-option>
          </el-select>
          <i  class="el-icon-right css_icon_2"></i>
        </div>
      </div>
      <div class="post_content">
        <div class="d-flex align-items-center" style="width: 100%">
          <div class="post_w_30" @click="toDashboard">
            <div class="icon">
              <img src="@/static/icon/dash/tc.svg"  alt="">
            </div>
            <span>Trang chủ</span>
          </div>
          <!--          // về chúng tôi -->
          <div class="post_w_30" @click="toAbout">
            <div class="icon">
              <img src="@/static/icon/dash/vct.svg" alt="">
            </div>
            <span>Về Chúng tôi</span>
          </div>
          <!--          // Tin tức -->
          <div class="post_w_30" @click="toNew()">
            <div class="icon">
              <img src="@/static/icon/dash/tt.svg" alt="">
            </div>
            <span>Tin tức</span>
          </div>
        </div>

        <div class="d-flex align-items-center" style="width: 100%">
          <div class="post_w_30" style="width: 50%" @click="toRecruitment()">
            <div class="icon">
              <img src="@/static/icon/dash/td.svg" alt="">
            </div>
            <span>Tuyển dụng</span>
          </div>
          <!--          // Quyền truy cập -->
          <div class="post_w_30" style="width: 50%" @click="toPer()">
            <div class="icon">
              <img src="@/static/icon/dash/vct.svg" alt="">
            </div>
            <span>Quyền truy cập</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters} from "vuex";
  import axios from "axios";
  export default {
    name: "HomeIndex",
    metaInfo() {
      return {
        title: "Trang chủ",
        input1: "",
      };
    },
    components: {},
    data() {
      return {
        loading: true,
        input1: "",
        options: [],
        value: [
          {
            key: '',
            title:'',
            source : '',
            id : ''
          }
        ],
        list: [],
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo"]),
    },
    mounted() {

    },
    methods: {
      handleOptionChange(){
        const myArray = this.value.split("_");
        let word = myArray[0];
        let id = myArray[1];
        let url = '';
        if (word == 'recruitment'){
          url = '/recruitment/edit/'+id
        }else {
          url = '/new/update/'+id
        }
        document.location.href = url
      },
      remoteMethod(query) {
        if (query !== '') {
          let rawData = {
            search: query,
          }
          this.loading = true;
          axios.get("user/search-post-recruitment",{params: rawData} ).then((response) => {
            this.loading = false;
            switch (response.code) {
              case 200:
                this.options = response.data;
                break;
              default:
                this.$notify.error({
                  title: "Error",
                  message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                });
                break;
            }
          });
        } else {
          this.options = [];
        }
      },
      hasRole(role) {
        let roles = [];
        if (
          undefined !== this.accountInfo.roles &&
          this.accountInfo.roles.length > 0
        ) {
          this.accountInfo.roles.forEach((e) => {
            roles.push(e.id);
          });
        }
        if(roles.includes(1)) {
          console.log(1)
          return true;
        } else {
          console.log(2)
          return roles.includes(role);
        }
      },
      toPer() {
        if (this.hasRole(5) == false) {
          this.$notify({
            title: "Cảnh báo",
            message: "Bạn không có quyền sử dụng chức năng này",
            type: "warning",
          });
          return;
        }
        this.$router.push({name: "permission-list"});
      },
      toDashboard() {
        if (this.hasRole(2) == false) {
          this.$notify({
            title: "Cảnh báo",
            message: "Bạn không có quyền sử dụng chức năng này",
            type: "warning",
          });
          return;
        }
        this.$router.push({
          name: "page",
          params: { id: 1 },
        });
      },
      toAbout(){
        if (this.hasRole(2) == false) {
          this.$notify({
            title: "Cảnh báo",
            message: "Bạn không có quyền sử dụng chức năng này",
            type: "warning",
          });
          return;
        }
        this.$router.push({
          name: "about",
          params: { id: 7 },
        });
      },
      // tin tức
      toNew() {
        if (this.hasRole(3) == false) {
          this.$notify({
            title: "Cảnh báo",
            message: "Bạn không có quyền sử dụng chức năng này",
            type: "warning",
          });
          return;
        }
        this.$router.push({name: "new-list"});
      },
      toRecruitment() {
        if (this.hasRole(4) == false) {
          this.$notify({
            title: "Cảnh báo",
            message: "Bạn không có quyền sử dụng chức năng này",
            type: "warning",
          });
          return;
        }
        this.$router.push({name: "recruitment"});
      }
    },
    watch: {},
  };
</script>

<style lang="css" scoped src="@/static/css/home/index.css">
  .home-index {
  }
</style>
<style>
  .search_post .el-input-group__prepend {
    border-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;
    border-right: 0;
    padding: 0 0 0 10px;
  }

  .search_post .el-input-group__append {
    border-radius: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .index-search .el-input__inner{
    padding: 0 30px !important;
    border-radius: 20px!important;
  }
  .search_post input {
    border-left: none;
    border-right: none;
    outline: none !important;
  }
  .css_icon{
    position: relative;
    top: 30px;
    z-index: 99;
    left: 10px;
  }
  .css_icon_2{
    position: absolute;
    top: 15px;
    z-index: 99;
    right: 10px;
  }
</style>
