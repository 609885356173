import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

import Meta from "vue-meta";
import auth from "@/router/auth";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid", // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

const routes = [
  {
    path: "/",
    name: "home-index",
    component: Home,
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/login",
    name: "account-login",
    component: () => import("@/views/account/Login.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // quyền truy cập
  {
    path: "/permission",
    name: "permission-list",
    component: () => import("@/views/permission/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/permission/user-list",
    name: "user-list",
    component: () => import("@/views/permission/User.vue"),
    beforeEnter: auth.requireAuth,
  },
  // thông tin tài khoản
  {
    path: "/profile",
    name: "account-profile",
    component: () => import("@/views/account/Profile.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/profile/upload-avatar",
    name: "upload-avatar",
    component: () => import("@/views/account/Avatar.vue"),
    beforeEnter: auth.requireAuth,
  },
  // tin tức
  {
    path: "/new",
    name: "new-list",
    component: () => import("@/views/new/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/new/manage",
    name: "new-manage",
    component: () => import("@/views/new/Manage.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/new/create",
    name: "new-create",
    component: () => import("@/views/new/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/recruitment",
    name: "recruitment",
    component: () => import("@/views/recruitment/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/recruitment/create",
    name: "recruitment-create",
    component: () => import("@/views/recruitment/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/recruitment/edit/:id",
    name: "recruitment-edit",
    component: () => import("@/views/recruitment/Edit.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/new/update/:id",
    name: "new-update",
    component: () => import("@/views/new/Update.vue"),
    beforeEnter: auth.requireAuth,
  },
  //config Page
  {
    path: "/dashboard/:id",
    name: "page",
    component: () => import("@/views/dashboard/Page.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/about/:id",
    name: "about",
    component: () => import("@/views/about/Page.vue"),
    beforeEnter: auth.requireAuth,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
