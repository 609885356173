<template>
  <div
    class="component-header-top d-flex justify-content-between align-items-center"
  >
    <div class="logo-global" @click="toClient()">
      <img  src="@/static/icon/header-top/logo.svg" alt="">
    </div>

    <div class="d-flex align-items-center">
      <!-- menu  -->
      <div class="dash px-3" style="cursor: pointer" v-if="full_path != '/'">
        <el-tooltip
          class="item"
          effect="dark"
          content="Dashboard"
          placement="top"
        >
          <img @click="toDash()" src="@/static/icon/header-top/dash.svg" alt="">
        </el-tooltip>
      </div>

      <div class="noti px-3" style="cursor: pointer">
        <img src="@/static/icon/header-top/noti.svg" alt="">
      </div>

      <div
        class="dropdown-account"
        ref="dropdownProfile"
        @click="showMenuTop = !showMenuTop"
      >
        <img
          v-if="accountInfo.avatar"
          :src="accountInfo.avatar_full_path"
          class="avatar"
        />
        <span v-else><img src="@/static/icon/header-top/avatar.svg"/></span>
        <span class="name">
        {{ accountInfo.name }}
          <i class="el-icon-arrow-down"></i>
      </span>
        <ul v-if="showMenuTop">
          <a @click.stop.stop="toProfile()" class="d-flex align-items-center">
            <img src="@/static/icon/header-top/setting.svg" alt="">
            <span>Cài đặt tài khoản</span>
          </a>
          <a class="divider"></a>
          <a @click.stop.stop="confirmLogout">
            <img src="@/static/icon/header-top/logout.svg" alt="">
            <span>Thoát tài khoản</span>
          </a>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
  // import axios from "axios";
  import ls from "local-storage";
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "LayoutHeaderTop",

    data() {
      return {
        seach: "",
        showMenuTop: false,
        list: [],

        // BUTTONS
        isButtonConfirming: false,

      };
    },
    mounted() {
      document.addEventListener("click", this.documentClick);
    },

    destroyed() {
      document.removeEventListener("click", this.documentClick);
    },

    methods: {
      toClient(){
         window.open(process.env.VUE_APP_CLIENT_URL, '_blank');
      },
      toDash() {
        this.$router.push({name: "home-index"});
      },
      toProfile() {
        this.$router.push({name: "account-profile"});
      },

      documentClick(e) {
        let el = this.$refs.dropdownProfile;
        let target = e.target;
        if (el !== target && !el.contains(target)) {
          this.showMenuTop = false;
        }
      },

      ...mapActions("account", ["removeAccountInfo"], ["getAccountInfo"]),

      mouseLeave() {
        this.showMenuTop = false;
      },

      confirmLogout() {
        this.$confirm("Xác nhận thoát tài khoản", "Warning", {
          confirmButtonText: "Đồng ý",
          cancelButtonText: "Hủy",
          type: "warning",
        })
          .then(() => {
            ls('out', true);
            this.removeAccountInfo();
          })
          .catch(() => {
          });
      },
    },

    computed: {
      ...mapGetters("account", ["accountInfo"]),
      ...mapGetters(["path", "full_path"]),
    },
  };
</script>

<style lang="css" scoped>
  .component-header-top {
    height: 60px;
    padding: 0px 30px;
  }
  .dropdown-account {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
    padding: 0px 3px 0px 10px;
    position: relative;
  }

  .dropdown-account img {
    margin-right: 8px;
  }

  .dropdown-account .down {
    width: 10px;
    margin-right: 0px;
    margin-left: 5px;
  }

  .dropdown-account .name {
    font-size: 12px;
  }

  .dropdown-account ul {
    position: absolute;
    top: 50px;
    right: 0px;
    margin: 0px;
    width: 200px;
    background-color: #fff;
    padding: 5px 0px 5px 0px;
    z-index: 1000;
    border: 1px solid #f2f2f2;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
  }

  .dropdown-account ul a {
    list-style-type: none;
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    text-decoration: none;
    color: #2c3e50;
  }

  .dropdown-account ul a:hover {
    background-color: #f2f2f2;
  }

  .dropdown-account ul a.divider {
    height: 1px;
    background-color: #ccc;
    margin-bottom: 5px;
  }

  /* meun item  */
  .component-header-top .menu-item {
    padding: 10px 10px 14px 10px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
  }
</style>

<style>
  .component-header-top input {
    border-radius: 25px;
    height: 28px;
    font-size: 12px;
    border: none;
  }

  .component-header-top .el-input__icon {
    line-height: 28px;
  }

  .avatar {
    max-width: 100%;
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }

  /*******************/
  /**** EL DIALOG ****/
  /*******************/
  .el-dialog {
    border-radius: 24px;
    max-width: 600px;
    margin-top: 3vh !important;
  }

  .el-dialog__close {
    display: none !important;
  }

  .el-dialog__body {
    padding: 8px 20px;
  }

  .el-dialog__footer button {
    border-color: #6C5CE7;
    border-radius: 12px;
    height: 36px;
    width: 160px;
  }

  .el-dialog__footer button:nth-child(1) {
    color: #6C5CE7;
  }

  .el-dialog__footer button:nth-child(1):hover {
    color: #6C5CE7;
    border-color: #6C5CE7;
    background-color: rgba(200, 200, 200, 0.1);
  }

  .el-dialog__footer button:nth-child(2) {
    background-color: #6C5CE7;
  }

  .el-dialog__footer button:nth-child(2):hover {
    border-color: #6C5CE7;
    opacity: 0.9;
  }

  .changePasswordForm input {
    border: 1px solid #C0C4CC;
    height: 40px;
  }

  .changePasswordForm input:focus-visible {
    border: 1px solid #6C5CE7;
  }

  .changePasswordForm button:nth-child(1) {
    border-color: #6C5CE7;
    border-radius: 12px;
    height: 36px;
    width: 160px;
  }
</style>
