<template>
  <div class="menu-left py-3">
    <div class="title_menu_left d-flex align-items-center">
      <div @click="toDash()">
        <img class="pr-2" src="@/static/icon/menu-left/arrow-left.svg" alt="">
      </div>
      <span class="txt" v-if="checkMenu('permission')">Quyền truy cập</span>
      <span class="txt" v-if="checkMenu('dashboard')">Cài đặt trang chủ</span>
      <span class="txt" v-if="checkMenu('profile')">Cài đặt tài khoản</span>
      <span class="txt" v-if="checkMenu('about')">Cài đặt về chúng tôi</span>
      <span class="txt" v-if="checkMenu('new')">Bài viết</span>
      <span v-if="checkMenu('recruitment/create') || checkMenu('recruitment/edit')" class="txt" @click="goToRecruitment()">Tuyển dụng</span>
    </div>
    <div class="title_menu_left d-flex justify-content-between menu_new" v-if="checkMenu('new')">
      <span @click="toAddPost()" style="color: #FD9D19"><i class="el-icon-circle-plus-outline pr-1"></i>Thêm bài viết</span>
    </div>
    <!--    // menu phàn quyền truy cập -->
    <div v-if="checkMenu('permission')" class="content">
      <div class="subMenu" :class="full_path == '/permission' ? 'activeMenu' : ''" @click="toAddUser()">
        <span>Thêm tài khoản</span>
      </div>
      <div class="subMenu" :class="full_path == '/permission/user-list' ? 'activeMenu' : ''" @click="toUserList()">
        <span>Danh sách tài khoản</span>
      </div>
    </div>
    <!--    // menu phàn cài đặt tài khoản -->
    <div v-if="checkMenu('profile')" class="content">
      <div class="subMenu" :class="full_path == '/profile' ? 'activeMenu' : ''" @click="toProfile()">
        <span>Thông tin</span>
      </div>
      <div class="subMenu" :class="full_path == '/profile/upload-avatar' ? 'activeMenu' : ''" @click="toUpload()">
        <span>Ảnh đại diện</span>
      </div>
    </div>
    <!--    // menu phàn cài đặt trang chu -->
    <div v-if="checkMenu('dashboard')" class="content">
      <div class="subMenu" v-for="item in config_page" :key="item.id"  :class="$route.params.id == item.id  ? 'activeMenu' : ''" @click="toPage(item.id)">
        <div class="d-flex justify-content-between" >
          <div style="margin-top: 8px"><span>{{item.title}} </span></div>
          <div style="margin-right: 10px" v-if="item.id != 1">
            <el-dropdown>
              <el-button class="style-button" >
                <i class="el-icon-more"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item ><div @click="moveUpItem(item.id)"><i class="el-icon-top"></i> Di chuyển lên</div></el-dropdown-item>
                <el-dropdown-item ><div @click="moveDownItem(item.id)"><i class="el-icon-bottom"></i> Di chuyển xuống</div></el-dropdown-item>
                <el-dropdown-item>
                  <div @click="getDialogVisible(item.id)"><i class="el-icon-edit"></i> Đổi tên</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Thay đổi title page"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-input placeholder="Title" v-model="title"></el-input>
      <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">Hủy</el-button>
                    <el-button type="primary" @click="changeTitle(id_page)">Xác nhận</el-button>
                    </span>
    </el-dialog>
<!--    //menu phan cai dat about-->
    <div v-if="checkMenu('about')" class="content">
    <div class="subMenu" v-for="item in config_page_about" :key="item.id" :class="$route.params.id == item.id  ? 'activeMenu' : ''" @click="toPageAbout(item.id)">
      <div class="d-flex justify-content-between">
        <div style="margin-top: 8px"><span>{{item.title}} </span></div>
        <div style="margin-right: 10px" >
          <el-dropdown>
            <el-button class="style-button">
              <i class="el-icon-more"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item ><div @click="moveUpItem(item.id)"><i class="el-icon-top"></i> Di chuyển lên</div></el-dropdown-item>
              <el-dropdown-item ><div @click="moveDownItem(item.id)"><i class="el-icon-bottom"></i> Di chuyển xuống</div></el-dropdown-item>
              <el-dropdown-item>
                <div @click="getDialogVisible(item.id)"><i class="el-icon-edit"></i> Đổi tên</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    title="Thay đổi title page"
    :visible.sync="dialogVisible"
    width="30%"
  >
    <el-input placeholder="Title" v-model="title"></el-input>
    <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">Hủy</el-button>
                    <el-button type="primary" @click="changeTitle(id_page)">Xác nhận</el-button>
                    </span>
  </el-dialog>
    <!--    // menu phàn Tin tức -->
    <div class="menu_content_new" v-if="checkMenu('new')">
      <div class="px-1">
        <el-input
          placeholder="Tìm kiếm bài viết"
          prefix-icon="el-icon-search"
          v-model="filter.title"
          @keyup.enter.native="getPost()"
        >
        </el-input>
      </div>
      <el-select clearable @change="changeTag()" class="pt-3 px-1" v-model="tagSelect" placeholder="Chọn">
        <el-option
          v-for="item in listTags"
          :key="item.id"
          :label="item.title"
          :value="item.id">
        </el-option>
      </el-select>
      <div class="d-flex justify-content-between align-items-center">
        <div class="count">
          <span>{{ totalPost }} bài viết</span>
        </div>
        <div class="d-flex justify-content-end" style="cursor: pointer">
          <el-dropdown trigger="click" @command="actionOrder">
          <span class="el-dropdown-link d-flex align-items-center count">
            <i class="el-icon-s-operation"></i>
            <div class="pl-2">Sắp xếp</div>
          </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item class="d-flex align-items-center" command="1"
                ><img src="@/static/icon/recruitment/arrow.png" alt="" /><span
                  class="pl-1"
                >A->Z</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="2"
                ><img src="@/static/icon/recruitment/arrow.png" alt="" /><span
                  class="pl-1"
                >Z->A</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="3"
                ><img src="@/static/icon/recruitment/up.png" alt="" /><span
                  class="pl-1"
                >Mới nhất</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="4"
                ><img src="@/static/icon/recruitment/down.png" alt="" /><span
                  class="pl-1"
                >Cũ nhất</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="5"
                ><img src="@/static/icon/recruitment/new.png" alt="" /><span
                  class="pl-1"
                >Mới chỉnh sửa</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="6"
                ><img src="@/static/icon/recruitment/view.png" alt="" /><span
                  class="pl-1"
                >Xem nhiều nhất</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="7"
                ><img src="@/static/icon/recruitment/hour.png" alt="" /><span
                  class="pl-1"
                >Hết hạn</span
                ></el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="d-flex flex-column pt-2">
        <div class="dataPost" v-for="item in listPost" :key="item.id" @click="viewUpdate(item);"
          :class="$route.params.id == item.id ? 'activeMenu' : ''"
        >
          <div class="date d-flex align-items-center">
            <i class="el-icon-date pr-1"></i>
            <span>{{ item.created_at }}</span>
          </div>
          <div class="title">
            <span>{{ item.title }}</span>
          </div>
          <div class="d-flex justify-content-between view">
            <div v-if="item.auth">
              <span>{{ item.auth.name }}</span>
            </div>
            <div class="d-flex align-items-center">
              <i class="el-icon-view pr-1"></i>
              <span>{{ item.view_count }}</span>
            </div>
          </div>
        </div>
        <div
          class="china-panigation-1988 d-flex justify-content-end pt-1"
        >
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pagination.per_page"
            :total="pagination.total"
            :current-page="filter.page"
            @current-change="changeCurrentPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="menu_content_new" style="padding-top: 0;" v-if="checkMenu('recruitment/create') || checkMenu('recruitment/edit')">
      <div class="d-flex justify-content-between menu_new mb-3" style="padding: 0 10px">
        <!--        <span @click="goToRecruitment()">Tuyển dụng</span>-->
        <span @click="goToAddRecruitment()" style="color: #FD9D19" v-if="checkMenu('recruitment/edit')"><i class="el-icon-circle-plus-outline pr-1"></i> Thêm tuyển dụng</span>
      </div>
      <div class="px-1">
        <el-input
          placeholder="Tìm tin tuyển dụng"
          prefix-icon="el-icon-search"
          v-model="filter.title"
          @keyup.enter.native="fetchRecruitment()"
        >
        </el-input>
        <el-select
          v-model="filter.profession"
          placeholder="Ngành nghề"
          style="width: 100%"
          class="mt-3"
          @change="fetchRecruitment()"
          clearable
        >
          <el-option
            v-for="(profession, index) in config.profession"
            :key="index"
            :label="profession"
            :value="index"
          ></el-option>
        </el-select>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="count">
          <span>{{ totalRecruitment }} Tin tuyển dụng</span>
        </div>
        <div class="d-flex justify-content-end" style="cursor: pointer">
          <el-dropdown trigger="click" @command="actionOrderRe">
          <span class="el-dropdown-link d-flex align-items-center count">
            <i class="el-icon-s-operation"></i>
            <div class="pl-2">Sắp xếp</div>
          </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item class="d-flex align-items-center" command="1"
                ><img src="@/static/icon/recruitment/arrow.png" alt="" /><span
                  class="pl-1"
                >A->Z</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="2"
                ><img src="@/static/icon/recruitment/arrow.png" alt="" /><span
                  class="pl-1"
                >Z->A</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="3"
                ><img src="@/static/icon/recruitment/up.png" alt="" /><span
                  class="pl-1"
                >Mới nhất</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="4"
                ><img src="@/static/icon/recruitment/down.png" alt="" /><span
                  class="pl-1"
                >Cũ nhất</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="5"
                ><img src="@/static/icon/recruitment/new.png" alt="" /><span
                  class="pl-1"
                >Mới chỉnh sửa</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="6"
                ><img src="@/static/icon/recruitment/view.png" alt="" /><span
                  class="pl-1"
                >Xem nhiều nhất</span
                ></el-dropdown-item
                >
                <el-dropdown-item class="d-flex align-items-center" command="7"
                ><img src="@/static/icon/recruitment/hour.png" alt="" /><span
                  class="pl-1"
                >Hết hạn</span
                ></el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="d-flex flex-column pt-2">
        <div class="dataPost" v-for="item in listRecruitment" :key="item.id" @click="goToRecruitmentDetail(item);"
          :class="$route.params.id == item.id ? 'activeMenu' : ''"
        >
          <div class="date d-flex align-items-center">
            <i class="el-icon-date pr-1"></i>
            <span>{{ item.created_at }}</span>
          </div>
          <div class="title">
            <span>{{ item.name }}</span>
          </div>
          <div class="d-flex justify-content-between view">
            <div>
              <span>{{ item.creator_name }}</span>
            </div>
          </div>
        </div>
        <div
          class="china-panigation-1988 d-flex justify-content-end pt-1"
        >
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pagination.per_page"
            :total="pagination.total"
            :current-page="filter.page"
            @current-change="changeCurrentPageRecruitment"
          >
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "HomeIndex",

  metaInfo() {
    return {
      title: "Menu",



    };
  },

  data() {
    return {
      loading: true,
      dialogVisible: false,
      isCollapse: true,
      listPost: [],
      listTags: [],
      tagSelect: '',
      filter: {
        title: "",
        tags: [],
        page: 1,
        per_page: 15,
        titleRecruitment: "",
        profession: "",

        asc_text: '',
        desc_text: '',
        desc_creat: '',
        asc_creat: '',
        edit_update: '',
        view_count_desc: '',
      },
      pagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        total_pages: 0,
      },
      totalPost: 0,
      config: {},
      listRecruitment: [],
      config_page: [],
      config_page_about: [],
      id_page : null,
      title : null,
      totalRecruitment: 0

    };
  },

  computed: {
    ...mapGetters("account", ["logged", "accountInfo"]),
    ...mapGetters(["path", "full_path"]),
  },
  mounted() {
    if(this.checkMenu('new')) {
      this.getPost();
      this.getTags();
    }
    if(this.checkMenu('recruitment/create') || this.checkMenu('recruitment/edit')) {
      this.fetchRecruitment();
      this.fetchConfig();
    }
    if(this.checkMenu('dashboard')) {
      this.getConfigPage();
    }
    if(this.checkMenu('about')) {
      this.getConfigPageAbout();
    }
  },
  methods: {
    toDash() {
      this.$router.push({name: "home-index"});
    },
    // thêm tài khoản
    toAddUser() {
      this.$router.push({name: "permission-list"});
    },
    // ds tài khoản
    toUserList() {
      this.$router.push({name: "user-list"});
    },
    // cài đặt tài khoản
    toProfile() {
      this.$router.push({name: "account-profile"});
    },
    toUpload() {
      this.$router.push({name: "upload-avatar"});
    },
    getDialogVisible(item){
      this.id_page = item
      this.dialogVisible = true
    },
    getConfigPage() {
      axios.get("/page")
        .then(response => {
          switch (response.code) {
            case 200:
              this.config_page = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });

    },
    getConfigPageAbout() {
      axios.get("/page/about")
        .then(response => {
          switch (response.code) {
            case 200:
              this.config_page_about = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });

    },
    changeTitle(item){
      let param = {
        title : this.title,
      }
      axios.get("/page/change-title/"+item,{params: param})
        .then(response => {
          switch (response.code) {
            case 200:
              this.$notify({
                title: "Thành công",
                message: "Thay đổi tên thành công",
                type: "success",
              });
              this.dialogVisible = false
              this.getConfigPage();
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
    },
    moveUpItem(item){
      axios.get("/page/up/"+item)
        .then(response => {
          switch (response.code) {
            case 200:
              this.$notify({
                title: "Thành công",
                message: "Thay đổi vị trí thành công",
                type: "success",
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
    },
    moveDownItem(item){
      axios.get("/page/down/"+item)
        .then(response => {
          switch (response.code) {
            case 200:
              this.$notify({
                title: "Thành công",
                message: "Thay đổi vị trí thành công",
                type: "success",
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
    },
    toPage(item){
      this.id_page = item
      this.$router.push({
        name: "page",
        params: { id: item },
      });
    },
    toPageAbout(item){
      this.id_page = item
      this.$router.push({
        name: "about",
        params: { id: item },
      });
    },
    checkMenu(menu) {
      return this.full_path.includes(menu);
    },
    // ds post
    changeCurrentPage(currentPage) {
      this.filter.page = currentPage;
      this.getPost();
    },
    changeCurrentPageRecruitment(currentPage) {
      this.pagination.page = currentPage;
      this.fetchRecruitment();
    },
    getPost() {
      this.loading = true;
      let filter = {
        title: this.filter.title,
        page: this.filter.page,
        per_page: this.filter.per_page,
        // tags: this.filter.tags,

        asc_text: this.filter.asc_text,
        desc_text: this.filter.desc_text,
        desc_creat: this.filter.desc_creat,
        asc_creat: this.filter.asc_creat,
        edit_update: this.filter.edit_update,
        view_count_desc: this.filter.view_count_desc,
      }
      if(this.tagSelect) {
        filter.tags = this.filter.tags
      }
      axios.get("post", {params: filter})
        .then(response => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.listPost = response.data.items;
              this.totalPost = response.data.pagination.count;
              this.pagination.total = response.data.pagination.total;
              this.pagination.per_page = response.data.pagination.per_page;

              this.filter.asc_text = '';
              this.filter.desc_text = '';
              this.filter.desc_creat = '';
              this.filter.asc_creat = '';
              this.filter.edit_update = '';
              this.filter.view_count_desc = '';
              break;
            case 422:
              this.$notify({
                title: "Có lỗi xảy ra",
                message: Object.values(response.data)[0],
                type: "warning",
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
    },
    changeTag() {
      this.filter.tags = [];
      this.filter.tags.push(this.tagSelect)
      this.getPost();
    },
    actionOrder(command) {
      console.log(command, 'command')
      if(command == 1) {
        this.filter = {
          asc_text: 1,
          desc_text: '',
          desc_creat: '',
          asc_creat: '',
          edit_update: '',
          view_count_desc: '',
        };
      }
      if(command == 2) {
        this.filter = {
          asc_text: '',
          desc_text: 1,
          desc_creat: '',
          asc_creat: '',
          edit_update: '',
          view_count_desc: '',
        };
      }
      if(command == 3) {
        this.filter = {
          asc_text: '',
          desc_text: '',
          desc_creat: 1,
          asc_creat: '',
          edit_update: '',
          view_count_desc: '',
        };
      }
      if(command == 4) {
        this.filter = {
          asc_text: '',
          desc_text: '',
          desc_creat: '',
          asc_creat: 1,
          edit_update: '',
          view_count_desc: '',
        };
      }
      if(command == 5) {
        this.filter = {
          asc_text: '',
          desc_text: '',
          desc_creat: '',
          asc_creat: '',
          edit_update: 1,
          view_count_desc: '',
        };
      }
      if(command == 6) {
        this.filter = {
          asc_text: '',
          desc_text: '',
          desc_creat: '',
          asc_creat: '',
          edit_update: '',
          view_count_desc: 1,
        };
      }
      this.getPost();
    },
    getTags() {
      this.loading = true;
      axios.get("tag", )
        .then(response => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.listTags = response.data.items;
              break;
            case 422:
              this.$notify({
                title: "Có lỗi xảy ra",
                message: Object.values(response.data)[0],
                type: "warning",
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
    },
    // Xem chi tiết
    viewUpdate(item) {
      this.$router.push({
        name: "new-update",
        params: { id: item.id },
      });
    },
    toAddPost() {
      this.$router.push({
        name: "new-create",
      });
    },
    fetchConfig() {
      axios.get("recruitment/config").then((response) => {
        if (response.code == 200) {
          this.config = response.data;
        }
      });
    },
    fetchRecruitment(value = 3) {
      let filter = {
        name: this.filter.title,
        profession: this.filter.profession,
        page: this.pagination.page
      };
      if (value == 1) {
        filter.sort_name = "asc";
      }
      if (value == 2) {
        filter.sort_name = "desc";
      }
      if (value == 3) {
        filter.sort_id = "desc";
      }
      if (value == 4) {
        filter.sort_id = "asc";
      }
      if (value == 5) {
        filter.updated_at = "desc";
      }
      if (value == 6) {
        filter.sort_total_view = "desc";
      }
      if (value == 7) {
        filter.sort_due_date = true;
      }

      axios.get("recruitment", { params: filter }).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            this.listRecruitment = response.data.items;
            this.pagination.total = response.data.pagination.total;
            this.pagination.per_page = response.data.pagination.per_page;
            this.totalRecruitment = response.data.pagination.total;
            break;
          case 401:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: "Bạn không có quyền truy cập",
              type: "warning",
            });
            break;
          case 422:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: Object.values(response.data)[0],
              type: "warning",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    goToRecruitmentDetail(item) {
      this.$router.push({ name: "recruitment-edit", params: { id: item.id } });
    },
    goToAddRecruitment() {
      this.$router.push({ name: "recruitment-create" });
    },
    goToRecruitment() {
      this.$router.push({ name: "recruitment" });
    },
    actionOrderRe(command) {
      this.fetchRecruitment(command);
    },
  },
  watch: {},
};
</script>

<style lang="css" scoped src="@/static/css/layout/menuLeft.css">
</style>
<style>
  .menu_content_new input {
    background: #2A3850;
    border-radius: 40px;
    color: #fff;
  }
</style>
<style lang="css" scoped >
.style-button{
  background: #f0f8ff00;
  border: #f0f8ff00;
  color: white;
}
</style>
